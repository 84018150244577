(function () {
  'use strict';

  angular.module('canceropoleGsoApp').controller('ManifestationSubscriptionController', ManifestationSubscriptionController);

  ManifestationSubscriptionController.$inject = [
    'entity',
    '$log',
    'AlertService',
    '$stateParams',
    'SocietySearch',
    'PersonSearch',
    'EventRegistration',
    'DateUtils',
    '$state',
    'CustomUtils',
    '$timeout',
    'ModelConfig',
    '$rootScope',
    'Principal',
    '$http',
    '$filter',
    '$translate',
    'PersonMetier',
    'prices',
    'MainEventService',
    'eventOptions'
  ];

  function ManifestationSubscriptionController(
    entity,
    $log,
    AlertService,
    $stateParams,
    SocietySearch,
    PersonSearch,
    EventRegistration,
    DateUtils,
    $state,
    CustomUtils,
    $timeout,
    ModelConfig,
    $root,
    Principal,
    $http,
    $filter,
    $translate,
    PersonMetier,
    prices,
    MainEventService,
    eventOptions
  ) {
    //cache la barre de navigation
    $root.hideNavBar = !Principal.isAuthenticated();

    var vm = this;
    vm.mainEventId = $stateParams.id;
    vm.mainEvent = entity;
    vm.eventOptions = eventOptions;
    vm.prices = prices;
    vm.isSending = false;

    vm.eventOptions = CustomUtils.sortByKey(vm.eventOptions, 'position');

    var defaultCivilite = 'mr';

    vm.eventRegistration = {
      mainEventId: vm.mainEventId,
      civilite: defaultCivilite
    };

    vm.defaultCountry = 'FR';
    vm.countries = [];

    $http({
      method: 'GET',
      url: 'api/public/main-events/' + vm.mainEvent.id + '/managements'
    }).success(function (data) {
      vm.freeParticipations = [];
      vm.partialFreeParticipations = [];

      var freeType = ModelConfig.mainEvent.management.getFreeRegistrationType();
      var partialFreeType = ModelConfig.mainEvent.management.getPartialFreeRegistrationType();

      angular.forEach(data, function (aData) {
        //on ne garde que les types gratuits
        if (aData.type === freeType) {
          vm.freeParticipations.push(aData);
        } else if (aData.type === partialFreeType) {
          vm.partialFreeParticipations.push(aData);
        }
      });
    });

    var showError = function (error) {
      $log.error(error);
      AlertService.error(error);
    };

    vm.isAdmin = function () {
      if (Principal.hasAnyAuthority(['ROLE_ADMIN'])) {
        return true;
      }
      return false;
    };

    vm.isOpen = function () {
      if (!vm.mainEvent.publique) {
        return false;
      }

      var limitDate = vm.mainEvent.inscriptionLimitDate;
      limitDate = DateUtils.convertDateTimeFromServer(limitDate);
      //limitDate.setFullYear(2200);
      var now = new Date();
      var compare = DateUtils.compare(now, limitDate);
      if (compare <= 0) {
        return true;
      }
      return false;
    };

    vm.limitDates = [];

    if (vm.mainEvent.inscriptionLimitDate) {
      vm.limitDates.push({
        text: $translate.instant('canceropoleGsoApp.mainEvent.inscriptionLimitDate'),
        value: vm.mainEvent.inscriptionLimitDate
      });
    }
    if (vm.mainEvent.hasPoster && vm.mainEvent.posterLimitDate) {
      vm.limitDates.push({
        text: $translate.instant('canceropoleGsoApp.mainEvent.posterLimitDate'),
        value: vm.mainEvent.posterLimitDate
      });
    }
    if (vm.mainEvent.hasOral && vm.mainEvent.oralCommunicationLimitDate) {
      vm.limitDates.push({
        text: $translate.instant('canceropoleGsoApp.mainEvent.oralCommunicationLimitDate'),
        value: vm.mainEvent.oralCommunicationLimitDate
      });
    }
    if (!vm.mainEvent.freeRegistration && vm.mainEvent.inscriptionLimitDateEarlybird) {
      vm.limitDates.push({
        text: $translate.instant('canceropoleGsoApp.mainEvent.inscriptionLimitDateEarlybird'),
        value: vm.mainEvent.inscriptionLimitDateEarlybird
      });
    }

    vm.limitDates.sort(function (o1, o2) {
      return DateUtils.compare(o1.value, o2.value);
    });

    vm.load = function () {
      vm.initMetierDropDownList();
      vm.initCountryList();
      if (vm.mainEvent.freeRegistration) {
        vm.statusPrice = 0;
      }
      EventRegistration.getSessions(
        { id: vm.mainEventId },
        function (result) {
          vm.eventDays = result;
          var rangeId = 0;
          angular.forEach(vm.eventDays, function (eventDay) {
            eventDay.ranges = [];

            eventDay.eventSessions.sort(function (s1, s2) {
              var startDate = DateUtils.convertDateTimeFromServer(s1.startDate);
              var endDate = DateUtils.convertDateTimeFromServer(s2.startDate);
              return DateUtils.compare(startDate, endDate);
            });

            angular.forEach(eventDay.eventSessions, function (eventSession) {
              var startDate = DateUtils.convertDateTimeFromServer(eventSession.startDate);
              var endDate = DateUtils.convertDateTimeFromServer(eventSession.endDate);

              var range = null;
              angular.forEach(eventDay.ranges, function (aRange) {
                var start = DateUtils.inRange(startDate, aRange.startDate, aRange.endDate);
                var end = DateUtils.inRange(endDate, aRange.startDate, aRange.endDate);
                if (start && end) {
                  range = aRange;
                }
              });

              if (range === null) {
                range = {
                  id: rangeId,
                  startDate: startDate,
                  endDate: endDate,
                  sessions: []
                };
                rangeId++;
                range.sessions.push(eventSession);
                eventDay.ranges.push(range);
              } else {
                range.sessions.push(eventSession);
              }
              if (eventSession.mandatory === true && range.sessions.length === 1) {
                //finalement aucune session selectionnée par défaut
                range.selected = eventSession;
              }
            });
          });
        },
        showError
      );
    };

    var personSource = {
      datatype: 'json',
      datafields: [
        {
          name: 'name'
        }
      ],
      id: 'id',
      url: 'api/_search/',
      data: {
        size: '20'
      }
    };

    var personDataAdapter = new $.jqx.dataAdapter(personSource, {
      // jshint ignore:line
      loadServerData: function (serverdata, source, callback) {
        PersonSearch.query(
          {
            query: vm.personComboBoxSettings.searchString,
            page: 0,
            size: 20
          },
          function (result) {
            angular.forEach(result, function (item) {
              item.name = item.firstName + ' ' + item.lastName;
            });
            callback({
              records: result
            });
          }
        );
      }
    });

    vm.personComboBoxSettings = {
      displayMember: 'name',
      valueMember: 'id',
      minLength: 1,
      autoDropDownHeight: true,
      remoteAutoComplete: true,
      //remoteAutoCompleteDelay: 100,
      source: personDataAdapter,
      search: function (searchString) {
        searchString = searchString.replaceAll('/', '//');
        vm.personComboBoxSettings.searchString = searchString;
        personDataAdapter.dataBind();
      },
      created: function (args) {
        vm.personComboBox = args.instance;
      },
      select: function (event) {
        if (event.args) {
          var item = event.args.item;
          var person = item.originalItem;
          vm.eventRegistration.personId = person.id;
          vm.eventRegistration.firstName = person.firstName;
          vm.eventRegistration.lastName = person.lastName;
          vm.eventRegistration.email = person.email;
          vm.eventRegistration.phone = person.phone;
          vm.eventRegistration.portable = person.portable;
          vm.eventRegistration.city = person.city;
          vm.eventRegistration.civilite = person.civilite;
        }
      },
      unselect: function () {
        vm.eventRegistration.personId = null;
        vm.eventRegistration.firstName = null;
        vm.eventRegistration.lastName = null;
        vm.eventRegistration.email = null;
        vm.eventRegistration.phone = null;
        vm.eventRegistration.portable = null;
        vm.eventRegistration.city = null;
        vm.eventRegistration.civilite = defaultCivilite;
      },
      change: function () {
        var selection = vm.personComboBox.getSelectedItem();
        if (selection === null) {
          vm.eventRegistration.personId = null;
          vm.eventRegistration.firstName = null;
          vm.eventRegistration.lastName = null;
          vm.eventRegistration.email = null;
          vm.eventRegistration.phone = null;
          vm.eventRegistration.portable = null;
          vm.eventRegistration.city = null;
          vm.eventRegistration.civilite = defaultCivilite;
        }
      }
    };

    vm.civiliteSettings = {
      height: 30,
      width: 60,
      autoDropDownHeight: true,
      displayMember: 'label',
      valueMember: 'value',
      created: function (args) {
        vm.civiliteDropdownList = args.instance;
      },
      source: [
        {
          label: $translate.instant('canceropoleGsoApp.Civilite.mr'),
          value: 'mr'
        },
        {
          label: $translate.instant('canceropoleGsoApp.Civilite.mme'),
          value: 'mme'
        }
      ]
    };

    //vm.selectedSociety = "test";
    var societySource = {
      datatype: 'json',
      datafields: [
        {
          name: 'name'
        }
      ],
      id: 'id',
      url: 'api/_search/',
      data: {
        size: '20'
      }
    };

    var societyDataAdapter = new $.jqx.dataAdapter(societySource, {
      // jshint ignore:line
      loadServerData: function (serverdata, source, callback) {
        if (!CustomUtils.isEmpty(vm.societyComboBoxSettings.searchString)) {
          var query = 'name:' + vm.societyComboBoxSettings.searchString;
          query += ' or ';
          query += 'shortName:' + vm.societyComboBoxSettings.searchString;
          SocietySearch.query(
            {
              query: query,
              page: 0,
              size: 8
            },
            function (result) {
              callback({
                records: result
              });
            },
            showError
          );
        }
      }
    });

    vm.societyComboBoxSettings = {
      displayMember: 'name',
      valueMember: 'id',
      minLength: 1,
      remoteAutoComplete: true,
      autoDropDownHeight: true,
      //remoteAutoCompleteDelay: 100,
      source: societyDataAdapter,
      search: function (searchString) {
        searchString = searchString.replaceAll('/', '//');
        vm.societyComboBoxSettings.searchString = searchString;
        societyDataAdapter.dataBind();
      },
      created: function (args) {
        vm.societyComboBox = args.instance;
      },
      select: function (event) {
        if (event.args) {
          var item = event.args.item;
          var society = item.originalItem;
          vm.eventRegistration.societyId = society.id;
          vm.eventRegistration.societyName = society.name;

          //si pas d'info sur la ville de l'utilisateur
          //alors on prend celle de la ville
          if (vm.eventRegistration.city == null || vm.eventRegistration.city.length === 0) {
            vm.eventRegistration.city = society.city;
          }

          //facturation
          vm.eventRegistration.facturationSocietyName = society.name;
          vm.eventRegistration.facturationSocietyAddress = society.address;
          vm.eventRegistration.facturationSocietyAddress2 = society.address2;
          vm.eventRegistration.facturationSocietyCity = society.city;
          vm.eventRegistration.facturationSocietyCp = society.cp;
        }
      },
      unselect: function () {
        vm.eventRegistration.societyId = null;
        vm.eventRegistration.societyName = null;
        vm.eventRegistration.facturationSocietyName = null;
        vm.eventRegistration.facturationSocietyAddress = null;
        vm.eventRegistration.facturationSocietyAddress2 = null;
        vm.eventRegistration.facturationSocietyCp = null;
        vm.eventRegistration.facturationSocietyCity = null;
      },
      change: function () {
        var selection = vm.societyComboBox.getSelectedItem();
        if (selection === null) {
          vm.eventRegistration.societyId = null;
          vm.eventRegistration.societyName = null;
          vm.eventRegistration.facturationSocietyName = null;
          vm.eventRegistration.facturationSocietyAddress = null;
          vm.eventRegistration.facturationSocietyAddress2 = null;
          vm.eventRegistration.facturationSocietyCp = null;
          vm.eventRegistration.facturationSocietyCity = null;
        }
      }
    };

    var displayMember = 'name';
    if (vm.mainEvent.lang === 'en') {
      displayMember = 'nameEn';
    }

    vm.initMetierDropDownList = function () {
      PersonMetier.query(
        {},
        function (result) {
          result.sort(function (r1, r2) {
            var l1 = r1[displayMember].toLowerCase();
            var l2 = r2[displayMember].toLowerCase();
            return l1.localeCompare(l2);
          });

          var dataAdapter = new $.jqx.dataAdapter({
            // jshint ignore:line
            localdata: result,
            datatype: 'json',
            datafields: [
              {
                name: 'id'
              },
              {
                name: 'name'
              },
              {
                name: 'nameEn'
              },
              {
                name: 'personStatuses',
                type: 'array'
              }
            ]
          });
          vm.metierDropDownListSettings.source = dataAdapter;
        },
        showError
      );
    };

    vm.metierDropDownListSettings = {
      displayMember: displayMember,
      valueMember: 'id'
    };

    vm.metierSelected = false;
    vm.onMetierSelect = function (event) {
      var args = event.args;
      if (args) {
        if (vm.statusDropDownList != null) {
          vm.statusDropDownList.clearSelection();
          vm.status = null;
          vm.eventRegistration.totalPrice = null;
        }
        var metier = args.item.originalItem;
        vm.eventRegistration.personMetierId = metier.id;
        vm.metierSelected = true;

        metier.personStatuses.sort(function (r1, r2) {
          var l1 = r1[displayMember].toLowerCase();
          var l2 = r2[displayMember].toLowerCase();
          return l1.localeCompare(l2);
        });

        var dataAdapter = new $.jqx.dataAdapter({
          localdata: metier.personStatuses,
          datatype: 'json',
          datafields: [
            {
              name: 'id'
            },
            {
              name: 'name'
            },
            {
              name: 'nameEn'
            }
          ]
        });
        vm.statusDropDownListSettings.source = dataAdapter;
      }
    };

    vm.statusDropDownListSettings = {
      displayMember: displayMember,
      valueMember: 'id',
      autoDropDownHeight: true,
      created: function (args) {
        vm.statusDropDownList = args.instance;
      }
    };

    vm.getPrice = function (status) {
      if (vm.isPartialFreeRegistration() || vm.isFreeRegistration()) {
        return 0;
      }
      return MainEventService.getPriceForStatus(vm.prices, vm.mainEvent, status);
    };

    vm.onStatusSelect = function (event) {
      var args = event.args;
      if (args) {
        var label = args.item.label;
        var value = args.item.value;

        var status = {
          name: label,
          id: value
        };

        vm.eventRegistration.personStatusId = value;
        vm.statusPrice = vm.getPrice(status);
      } else {
        vm.statusPrice = 0;
      }
      vm.calculateTotalPrice();
    };

    vm.initCountryList = function () {
      vm.countries = [];
      CustomUtils.getCountries().then(function (result) {
        angular.forEach(result, function (value, key) {
          if (value.alpha2Code === vm.defaultCountry) {
            vm.countryToSelect = value.name;
          }
          vm.countries.push({
            name: value.name
          });
        });
        var countriesDataAdapter = new $.jqx.dataAdapter({
          // jshint ignore:line
          localdata: vm.countries,
          datatype: 'json',
          datafields: [
            {
              name: 'name'
            }
          ]
        });
        vm.countrySettings.source = countriesDataAdapter;
      });
    };

    vm.countrySettings = {
      displayMember: 'name',
      valueMember: 'name',
      created: function (args) {
        vm.countryComboBox = args.instance;
        $timeout(function () {
          vm.countryComboBox.val(vm.countryToSelect);
        }, 200);
      }
    };

    vm.onSessionSelection = function () {
      $log.info(arguments);
    };

    vm.sendRegistration = function () {
      vm.showValidityError = false;

      var send = vm.personForm.$valid && !vm.isSending;
      if (send && vm.sessionsForm) {
        send = vm.sessionsForm.$valid;
      }
      if (send && vm.facturationForm) {
        send = vm.facturationForm.$valid;
      }

      if (send) {
        vm.isSending = true;

        //country
        if (vm.facturationForm != null) {
          var country = vm.countryComboBox.getSelectedItem();
          vm.eventRegistration.facturationSocietyCountry = country.value;
        }

        //civilite
        if (CustomUtils.isUndefinedOrNull(vm.eventRegistration.civilite)) {
          vm.eventRegistration.civilite = defaultCivilite;
        }

        //registration date
        vm.eventRegistration.registrationDate = new Date();

        var eventSessions = [];
        angular.forEach(vm.eventDays, function (eventDay) {
          angular.forEach(eventDay.ranges, function (range) {
            if (range.selected) {
              eventSessions.push(range.selected);
            }
          });
        });

        var eventOptions = [];
        angular.forEach(vm.eventOptions, function (eventOption) {
          if (eventOption.selected === true) {
            eventOptions.push(eventOption);
          }
        });
        vm.eventRegistration.eventOptions = eventOptions;

        vm.eventRegistration.sessions = eventSessions;
        $log.info('Sending ', vm.eventRegistration);

        vm.eventRegistration.lastName = $filter('uppercase')(vm.eventRegistration.lastName);
        vm.eventRegistration.firstName = $filter('capitalize')(vm.eventRegistration.firstName);

        vm.eventRegistration.phone = CustomUtils.correctPhone(vm.eventRegistration.phone);
        vm.eventRegistration.portable = CustomUtils.correctPhone(vm.eventRegistration.portable);

        EventRegistration.register(vm.eventRegistration, vm.onRegistrationSaved, function (error) {
          vm.isSending = false;
          showError(error);
        });
      } else if (
        vm.personForm.$invalid === true ||
        (vm.facturationForm && vm.facturationForm.$invalid === true) ||
        vm.sessionsForm.$invalid === true
      ) {
        vm.showValidityError = true;
      }
    };

    vm.onRegistrationSaved = function (result) {
      $log.info(result);
      var registrationId = result.id;
      vm.isSending = false;
      $state.go('manifestationSubscriptionConfirmation', {
        id: registrationId,
        confirmation: result
      });
    };

    var isCp = function (cp) {
      var reg = /^\d{5}$/;
      return reg.test(cp);
    };

    vm.checkPhone = function (event) {
      var correctedPhone = CustomUtils.correctPhone(vm.eventRegistration.phone);
      var isPhone = CustomUtils.isPhoneNumber(correctedPhone);
      if (vm.eventRegistration.phone !== correctedPhone) {
        $timeout(function () {
          $(event.target).jqxMaskedInput('clear');
          $(event.target).jqxMaskedInput('val', correctedPhone);
        }, 200);
      }
      vm.personForm.phone.$setValidity('minlength', isPhone);
    };

    vm.checkPortable = function (event) {
      var correctedPhone = CustomUtils.correctPhone(vm.eventRegistration.portable);
      if (!CustomUtils.isEmpty(correctedPhone)) {
        var isPhoneNumber = CustomUtils.isPhoneNumber(vm.eventRegistration.portable);
        vm.personForm.portable.$setValidity('minlength', isPhoneNumber);
        if (vm.eventRegistration.portable !== correctedPhone) {
          $timeout(function () {
            $(event.target).jqxMaskedInput('clear');
            $(event.target).jqxMaskedInput('val', correctedPhone);
          }, 200);
        }
      } else {
        vm.personForm.portable.$setValidity('minlength', true);
      }
    };

    vm.checkCp = function () {
      if (!isCp(vm.eventRegistration.facturationSocietyCp)) {
        vm.facturationForm.facturationCp.$setValidity('minlength', false);
      } else {
        vm.facturationForm.facturationCp.$setValidity('minlength', true);
      }
    };

    vm.calculateTotalPrice = function () {
      if (vm.isFreeRegistration()) {
        vm.eventRegistration.totalPrice = 0;
      } else {
        vm.eventRegistration.totalPrice = vm.statusPrice;
        angular.forEach(vm.eventDays, function (eventDay) {
          angular.forEach(eventDay.ranges, function (range) {
            if (range.selected && range.selected.price) {
              vm.eventRegistration.totalPrice += range.selected.price;
            }
          });
        });
        angular.forEach(vm.eventOptions, function (eventOption) {
          if (eventOption.selected === true && eventOption.price > 0) {
            vm.eventRegistration.totalPrice += eventOption.price;
          }
        });
      }
    };

    vm.isFreeRegistration = function () {
      if (!CustomUtils.isUndefinedOrNull(vm.eventRegistration.personId)) {
        for (var i = 0; i < vm.freeParticipations.length; i++) {
          var freeParticipation = vm.freeParticipations[i];
          if (freeParticipation.personId === vm.eventRegistration.personId) {
            return true;
          }
        }
      }
      return false;
    };

    vm.isPartialFreeRegistration = function () {
      if (!CustomUtils.isUndefinedOrNull(vm.eventRegistration.personId)) {
        for (var i = 0; i < vm.partialFreeParticipations.length; i++) {
          var freeParticipation = vm.partialFreeParticipations[i];
          if (freeParticipation.personId === vm.eventRegistration.personId) {
            return true;
          }
        }
      }
      return false;
    };

    vm.load();
  }
})();
