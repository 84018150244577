(function () {
    'use strict';
    angular.module('canceropoleGsoApp').controller('AbstractDisplay', AbstractDisplay);
    AbstractDisplay.$inject = ['EventAbstract'];
    function AbstractDisplay(EventAbstract) {
        var ctrl = this;

        ctrl.sameSociety = function (s1, s2) {
            s1 = s1.name.toLowerCase();
            s2 = s2.name.toLowerCase();
            return s1 === s2;
        };

        ctrl.loadAbstract = function () {
            EventAbstract.getAllInformations({ id: ctrl.aid }, function (result) {
                ctrl.axeName = result.axeName;
                ctrl.sessionName = result.sessionName;
                ctrl.abstract = result.eventAbstract;

                ctrl.abstractPeople = result.abstractPeople;
                ctrl.abstractSocieties = [];

                angular.forEach(ctrl.abstractPeople, function (person) {
                    person.societyIndexes = [];
                    angular.forEach(result.abstractSocieties, function (society) {
                        if (society.abstractPersonId === person.id) {
                            var societyAlreadyExists = false;
                            angular.forEach(ctrl.abstractSocieties, function (existingSociety, key) {
                                if (ctrl.sameSociety(existingSociety, society)) {
                                    societyAlreadyExists = true;
                                    person.societyIndexes.push(key + 1);
                                }
                            });
                            if (!societyAlreadyExists) {
                                ctrl.abstractSocieties.push(society);
                                person.societyIndexes.push(ctrl.abstractSocieties.length);
                            }
                        }
                    });
                    person.societyIndexes.sort();
                });
            });
        };

        this.$onChanges = function () {
            ctrl.loadAbstract();
        };
    }
})();
